



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AnomaliesItemTitle extends Vue {
  @Prop({ required: true, default: null, type: String })
  private type: string;

  private showIcon = false;

  get title(): string {
    this.showIcon = false;
    switch (this.type) {
      case "geolocationProblems":
        return "problemi di geolocalizzazione";
      case "missingCheck":
        return "check orari mancanti";
      case "otherAnomalies":
        this.showIcon = true;
        return "altre anomalie";
      default:
        return "";
    }
  }
}
