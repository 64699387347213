






import { Component, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CosmopolRefreshBtn extends Vue {
  @PropSync("isLoading", { required: false, default: false, type: Boolean })
  private _isLoading: boolean;

  private spin = false;

  private onClick(): void {
    this.$emit("on-refresh");
    this.spin = true;
    let timer = setTimeout(() => {
      this._isLoading ? clearTimeout(timer) : (this.spin = false);
    }, 2000);
  }
}
