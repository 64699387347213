






































































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  CellType,
  TableHeader,
  TableType,
} from "@/models/utils/c-table.interface";
import { Pagination } from "@/models/utils/pagination.interface";
import { Roles } from "@/models/entities/role.interface";
import { CBtn } from "@/models/utils/c-btn.interface";
import { VDataTable } from "vuetify/lib/components/VDataTable/VDataTable";
import TableCell from "@/components/CosmopolDatatable/components/TableCell.vue";
import AnomaliesItemTitle from "@/components/CosmopolDatatable/components/AnomaliesItemTitle.vue";
import EmergenciesExpanded from "@/components/CosmopolDatatable/components/EmergenciesExpanded.vue";
import EmergenciesShow from "@/components/CosmopolDatatable/components/EmergenciesShow.vue";
import CosmopolRefreshBtn from "@/components/CosmopolRefreshBtn.vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import SearchModule from "@/store/modules/search.module";

const authModule = getModule(AuthModule, store);
const searchModule = getModule(SearchModule, store);

@Component({
  components: {
    AnomaliesItemTitle,
    EmergenciesExpanded,
    EmergenciesShow,
    TableCell,
    CosmopolRefreshBtn,
  },
})
export default class CosmopolDatatable extends Vue {
  @Prop({ required: false, default: () => [], type: Array })
  private items: unknown[];
  @Prop({ required: false, default: () => [], type: Array })
  private headers: TableHeader[];
  @Prop({ required: false, default: TableType.DEFAULT, type: Number })
  private tableType: TableType;
  @Prop({ required: false, default: () => [], type: Array })
  private expandedItems: unknown[];
  @Prop({ required: false, default: false, type: Boolean })
  private isLoading: boolean;
  @Prop({ required: false, default: "", type: [String, Boolean] })
  private itemKey: string | number;
  @Prop({ required: false, type: Number })
  private serverItemsLength: number;

  @PropSync("pagination", { required: true, type: Object })
  private _pagination: Pagination;

  private expanded = [];
  private itemsPerPageOptions = [10, 25, 50, 100];
  private showOtherServices = false;

  get cantViewDetail(): boolean {
    return authModule.roles.indexOf(Roles.CANT_VIEW_DETAILS) > -1;
  }

  get search$(): string {
    return this.serverItemsLength === undefined ? this.search : "";
  }

  get search(): string {
    return searchModule.search;
  }

  get noDataText(): string {
    return this.search
      ? `Nessun dato disponibile per ${this.search}`
      : "Nessun dato disponibile";
  }

  @Watch("search")
  private onSearch(search: string) {
    this.$set(this._pagination, "page", 1);
    this.$set(this._pagination, "search", search);
    this.$emit("update-pagination");
  }

  private otherServicesBtn(isExpanded: boolean, n: number): Omit<CBtn, "type"> {
    return {
      btnClass: "c-btn-outlined",
      label: `${
        isExpanded ? "Nascondi" : "Mostra"
      } altri servizi regolari (${n})`,
      labelClass: "c-label-dark",
      color: "var(--v-light-base)",
      icon: null,
    };
  }

  private hideExpanded(): void {
    (this.$refs.cosmopolDatatable as VDataTable).expand();
  }

  private onChangeItemPerPage(itemPerPage: number): void {
    this.$set(this._pagination, "rows", itemPerPage);
    this.$set(this._pagination, "page", 1);
    this.$emit("update-pagination");
  }

  private onPaginationInput(page: number): void {
    this.$set(this._pagination, "page", page);
    this.$emit("update-pagination");
  }

  private rowClicked(item, cellType: CellType): void {
    if (this.cantViewDetail) return;
    if (cellType !== CellType.ARCHIVE && cellType !== CellType.STATUS)
      this.$emit("click-row", item);
  }
}
