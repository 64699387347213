var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"cosmopolDatatable",staticClass:"cosmopol-datatable",attrs:{"search":_vm.search$,"items":_vm.items,"headers":_vm.headers,"loading":_vm.isLoading,"item-key":_vm.itemKey,"server-items-length":_vm.serverItemsLength,"items-per-page":_vm._pagination.rows,"footer-props":{
    itemsPerPageOptions: _vm.itemsPerPageOptions,
  },"options":{ page: _vm._pagination.page },"single-expand":"","disable-sort":"","hide-default-footer":"","loading-text":"Caricamento dati ...","no-data-text":_vm.noDataText},scopedSlots:_vm._u([(_vm.tableType === _vm.TableType.DEFAULT_WITH_REFRESH)?{key:"header.refresh",fn:function(){return [_c('cosmopol-refresh-btn',{on:{"on-refresh":function($event){return _vm.$emit('on-refresh')}}})]},proxy:true}:null,{key:"item",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
  var expand = ref.expand;
  var isExpanded = ref.isExpanded;
return [(_vm.tableType === _vm.TableType.WITH_SUBCATEGORIES)?[_c('anomalies-item-title',{attrs:{"type":item.anomaliesType}}),_vm._l((item.anomalies),function(anomaly,anomalyIdx){return _c('tr',{key:(anomalyIdx + "_" + (item.anomaliesType) + "_a")},_vm._l((_vm.headers),function(header,headerIdx){return _c('td',{key:(headerIdx + "_" + (item.anomaliesType) + "_h")},[_c('table-cell',{key:(index + "_" + anomalyIdx + "_" + headerIdx),class:header.customClass
                ? header.customClass
                : 'c-label-regular c-txt-primary text-uppercase',attrs:{"header":header,"item":anomaly},on:{"update-status":function($event){return _vm.$emit('update-status', $event)},"on-archive":function($event){return _vm.$emit('on-archive', $event)}}})],1)}),0)})]:[_c('tr',{class:{
          'cosmopol-datatable-other-services':
            _vm.tableType === _vm.TableType.WITH_EXPANDABLE &&
            item[_vm.expandedItems] &&
            item[_vm.expandedItems].length,
          'cosmopol-datatable-hide-hover': _vm.cantViewDetail,
        }},_vm._l((_vm.headers),function(header,idx){return _c('td',{key:idx,on:{"click":function($event){return _vm.rowClicked(item, header.cellType)}}},[(header.cellType === _vm.CellType.ACTIONS)?_vm._t("actions",null,{"item":item,"index":index}):_c('table-cell',{key:(index + "_" + idx),class:header.customClass
                ? header.customClass
                : 'c-label-regular c-txt-primary text-uppercase',attrs:{"header":header,"item":item},on:{"update-status":function($event){return _vm.$emit('update-status', $event)},"on-archive":function($event){return _vm.$emit('on-archive', $event)}}})],2)}),0)],(
        _vm.tableType === _vm.TableType.WITH_EXPANDABLE &&
        item[_vm.expandedItems] &&
        item[_vm.expandedItems].length &&
        !isExpanded
      )?_c('emergencies-show',{attrs:{"customBtn":_vm.otherServicesBtn(false, item[_vm.expandedItems].length)},on:{"show-expand":function($event){return expand(!isExpanded)}}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
      var item = ref.item;
return [(_vm.tableType === _vm.TableType.WITH_EXPANDABLE)?[_c('tr',{staticClass:"cosmopol-datatable-other-services cosmopol-datatable-hide-hover"},[_c('td',{attrs:{"colspan":"1"}}),_c('td',{attrs:{"colspan":"2"}},[_c('cosmopol-btn',{attrs:{"btnType":_vm.BtnType.CUSTOM,"customBtn":_vm.otherServicesBtn(true, item[_vm.expandedItems].length)},on:{"on-click":_vm.hideExpanded}})],1),_c('td',{attrs:{"colspan":"7"}})]),_vm._l((item[_vm.expandedItems]),function(exItem,exItemIdx){return _c('emergencies-expanded',{key:exItemIdx,attrs:{"item":exItem,"trClass":exItemIdx === item[_vm.expandedItems].length - 1
            ? ''
            : 'cosmopol-datatable-other-services'}})})]:_vm._e()]}},{key:"footer",fn:function(footer){return [_c('v-container',{staticClass:"cosmopol-datatable-footer",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"cosmopol-table-items-per-page",attrs:{"value":_vm._pagination.rows,"dense":"","flat":"","solo":"","hide-details":"","hide-selected":"","items":_vm.itemsPerPageOptions,"background-color":"var(--v-light-base)","menu-props":{
              bottom: true,
              offsetY: true,
              contentClass: 'cosmopol-table-items-per-page-menu',
            }},on:{"change":_vm.onChangeItemPerPage}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{key:footer.props.pagination.page,attrs:{"value":_vm._pagination.page,"length":footer.props.pagination.pageCount,"total-visible":9},on:{"input":_vm.onPaginationInput}})],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }