





















import { CellType } from "@/models/utils/c-table.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

const TableCell = () =>
  import(/* webpackChunkName: "TableCell" */ "./TableCell.vue");

@Component({
  components: {
    "table-cell": TableCell,
  },
})
export default class EmergenciesExpanded extends Vue {
  @Prop({ required: true, default: {}, type: Object })
  private item: unknown;
  @Prop({ required: true, default: "", type: String })
  private trClass: string;

  private emergenciesValues = [
    {
      colspan: 1,
      header: {
        value: "services",
        cellType: CellType.DEFAULT,
      },
    },
    //  {
    //    colspan: 1,
    //    header: {
    //      value: "startTime",
    //      cellType: CellType.TIME,
    //      customProp: "endTime",
    //    },
    //  },
    {
      colspan: 1,
      header: {
        value: "fullName",
        cellType: CellType.EMPLOYEE,
        customProp: "isSubstitute",
      },
      customClass: "c-label-bold c-txt-primary text-capitalize",
    },
    {
      colspan: 1,
      header: {
        value: "phone",
        cellType: CellType.DEFAULT,
      },
    },
    //  {
    //    colspan: 1,
    //    header: {
    //      value: "stamping",
    //      cellType: CellType.CHIP,
    //    },
    //  },
  ];
}
