
































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CellType, TableHeader } from "@/models/utils/c-table.interface";
import format from "date-fns/format";
import { CBtn } from "@/models/utils/c-btn.interface";
import { CONDITIONS_ICON, CONDITIONS_TYPE } from "@/helpers/conditions";
import { CalendarJob } from "@/models/entities/calendar-job.interface";
import { Actions } from "@/models/entities/action.interface";

@Component
export default class TableCell extends Vue {
  @Prop({ required: true, default: null, type: Object })
  private header: TableHeader;
  @Prop({ required: true, default: null, type: Object })
  private item: CalendarJob | unknown; //TODO

  private hideServicesBtn: Omit<CBtn, "type"> = {
    btnClass: "c-btn-outlined",
    label: "Nascondi altri servizi regolari",
    labelClass: "c-label-dark",
    color: "var(--v-light-base)",
    icon: null,
  };

  private selectedStatus = null;
  private initialStatus = null;

  private mounted(): void {
    if (this.header.cellType === CellType.STATUS) {
      this.selectedStatus = this.item[this.header.value];
      this.initialStatus = this.item[this.header.value];
    }
  }

  private getChipIcon(type: unknown): string {
    return CONDITIONS_ICON(type as string);
  }

  private getChipType(type: unknown): string {
    return CONDITIONS_TYPE(type as string);
  }

  private getDate(date: unknown): string {
    return format(new Date(date as string), "dd/MM/yyyy");
  }

  private getTime(time: unknown): string {
    return time && (time as string).trim() != ""
      ? format(new Date(time as string), "HH:mm")
      : "--:--";
  }

  private onChangeStatus(lastActionId: number): void {
    const job = this.item as CalendarJob;
    if (
      lastActionId === Actions.SUBSTITUTES ||
      lastActionId === Actions.GPG_ONPOINT
    ) {
      this.$nextTick(() => {
        this.selectedStatus = this.initialStatus;
      });
    } else {
      this.initialStatus = this.selectedStatus;
    }
    this.$set(job, "lastActionId", lastActionId);
    this.$emit("update-status", job);
  }
}
