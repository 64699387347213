














import { CBtn } from "@/models/utils/c-btn.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class EmergenciesShow extends Vue {
  @Prop({ required: true, default: {}, type: Object })
  private customBtn: Omit<CBtn, "type">;
}
