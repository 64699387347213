export interface Action {
  actionId: number;
  actionName: string;
  actionClass: string;
}

export enum Actions {
  NONE = 1,
  ARRIVING = 2,
  NOANSWER = 3,
  SUBSTITUTES = 4,
  ARCHIVE = 5,
  GPG_ONPOINT = 6,
}
